// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
}

.modal-card-title,
.subtitle,
.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $family-heading;
}

.button,
.control.has-icons-left .icon,
.control.has-icons-right .icon,
.input,
.pagination-ellipsis,
.pagination-link,
.pagination-next,
.pagination-previous,
.select,
.select select,
.textarea {
  height: 2.534em;
}

.button {
  transition: all 200ms ease;
  font-weight: 500;
  font-family: $family-heading;

  &.is-active,
  &.is-focused,
  &:active,
  &:focus {
    box-shadow: 0 0 0 2px rgba($button-focus-border-color, 0.25);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &.is-active,
      &.is-focused,
      &:active,
      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 2px rgba($color, 0.25);
      }
    }
  }
}

.input,
.textarea {
  transition: all 200ms ease;
  box-shadow: none;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      a:not(.button) {
        color: $color-invert;
        text-decoration: underline;
      }
    }
  }
}

.card {
  box-shadow: none;
  border: 1px solid $grey-lighter;
  border-radius: $radius;

  .card-image {
    img {
      border-radius: $radius $radius 0 0;
    }
  }

  .card-header {
    box-shadow: none;
    border-bottom: 1px solid $grey-lighter;
    border-radius: $radius $radius 0 0;
  }
}

.card-header-title,
.menu-label,
.message-header,
.panel-heading {
  font-family: $family-heading;
  font-weight: normal;
}

.menu-list a {
  border-radius: $radius;
}

.navbar {
  border-radius: $radius;

  .navbar-item,
  .navbar-link {
    font-family: $family-heading;
    transition: all 300ms;
  }

  @include touch {
    .navbar-menu {
      background-color: inherit;
      border-radius: inherit;
    }
  }

  .navbar-dropdown .navbar-item {
    @include desktop {
      color: $text;
    }
  }

  &.is-transparent {
    background-color: transparent;
    .navbar-item,
    .navbar-link {
      color: rgba($text, 0.75);

      &.is-active {
        color: $text;
      }

      &:after {
        border-color: inherit;
      }
    }
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar-start,
      .navbar-end {
        > .navbar-item,
        .navbar-link {
          color: rgba($color-invert, 0.75);

          &.is-active {
            color: $color-invert;
          }
        }
      }
      @include touch {
        .navbar-item,
        .navbar-link {
          color: rgba($color-invert, 0.75);

          &.is-active {
            color: $color-invert;
          }
        }
      }
    }
  }
}

.hero {
  // Colors
  .navbar {
    background-color: $primary;
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}

.panel-block.is-active {
  color: $primary;
}
